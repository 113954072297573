.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin: 12px;

  &_logo {
    width: 200px;
    height: initial;
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #070707 !important;
    width: 100%;
    height: 50px;
    background: #3bfb65 !important;
    box-shadow: 0px 2.81481px 0px #02c82d;
    border: initial;
    border-radius: 8px;
    padding: 0 24px;
    cursor: pointer;

    svg {
      font-size: 20px;
      margin-left: 18px;
    }
  }
}

@media (min-width: 680px) {
  .navbar {
    flex-direction: row;
    max-width: 1024px;
    margin: 12px auto;
    padding: 0 12px !important;

    &_logo {
      width: 250px;
    }

    &_item {
      width: 250px;
      height: 55px;

      svg {
        font-size: 24px;
      }
    }
  }
}
