.edit-wrapper .action {
  margin: 0 12px;

  &-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    max-width: 680px;
    height: 55px;
    border: initial;
    border-radius: 8px;
    margin: 24px auto;
    background: #ff0000;
    box-shadow: 0px 4px 0px #c10000;
    color: #ffffff;
    cursor: pointer;
  }
}

.modal-action_remove,
.modal-action_close {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  height: 45px;
  border: initial;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 24px;
}

.modal-action {
  &_remove {
    background: #ff0000;
    box-shadow: 0px 4px 0px #c10000;
    color: #ffffff;
  }

  &_close {
    background: #1d1d1e;
    box-shadow: 0px 4px 0px rgba(5, 5, 5, 0.8);
    color: #ffffff;
  }
}
