.back_button {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  margin: 12px 0;
  border: initial;
  outline: initial;
  background: initial;

  svg {
    margin-right: 6px;
  }
}

@media (min-width: 680px) {
  .back_button {
    margin: 12px auto;
    font-size: 18px;
    justify-content: flex-start;
    height: 50px;
    max-width: 680px;
  }
}
