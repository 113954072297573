.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  color: #070707;
  background: #fbc53b;
  box-shadow: 0px 9.35068px 0px #d59900;
  border-radius: 8px;
  margin: 24px 12px;
  padding: 12px;

  svg {
    font-size: 24px;
    min-width: 35px;
    max-width: 35px;
    margin-right: 12px;
  }
}

@media (min-width: 992px) {
  .warning {
    max-width: 700px;
    margin: 24px auto;
  }
}
