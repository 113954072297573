@import '~bootstrap/scss/bootstrap';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: initial;
}

body {
  background: #f6f9ff;
}
